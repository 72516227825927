<template>
  <el-container>
    <el-container>
      <!-- 右侧栏 -->
      <el-aside :class="[ isCollapse ? 'el-aside_on' : '']">
        <div class="logo">
          <img :src="content[1].value" alt="">
        </div>
        <el-row class="tac">
          <el-col>

            <el-menu
              :default-active="isActiveMenu == '1' ? isActiveMenu : activeMenu"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#d8e4fb"
              text-color="#666"
              active-text-color="#fff"
              :collapse="isCollapse"
              :collapse-transition="false"
              :unique-opened="true"
            >

            <fragment v-for="(item,index) in menuList" :key="index">
            
              <fragment v-if="!item.children">
                <el-menu-item :index="(index+1).toString()" @click="toSubMenu(item.path,item,(index+1))">
                  <!-- <i class="iconfont" :class="item.icon" style="font-size: 20px; margin-right: 10px;"></i> -->
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </fragment>

              <fragment v-else>
                  <el-submenu :index="(index+1).toString()">
                    <template slot="title">
                      <!-- <i class="iconfont" :class="item.icon" style="font-size: 20px; margin-right: 10px;"></i> -->
                      <span v-show="!isCollapse">{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                      <div style="width: 100%; display: inline-block;" v-for="(iem,idx) in item.children" :key="idx" @click.stop="toPage(iem.path,item,((index+1) + '-' + (idx+1)))">
                        <el-menu-item :index="(index+1).toString() + '-' + (idx+1).toString()">
                          <!-- <i class="iconfont" :class="iem.icon" style="font-size: 20px; margin-right: 10px;"></i> -->
                          <span></span>{{ iem.name }}
                        </el-menu-item>
                      </div>
                    </el-menu-item-group>
                  </el-submenu>
                </fragment>

            </fragment>

            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <!-- 右侧栏 -->
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <!-- 头部 -->
        <el-main>
          <router-view />
        </el-main>
        <!-- 主体 -->
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import  Header  from '@/components/Header.vue';
import { getConfig } from '@/api/home';
import { mapState } from 'vuex';
export default {
  name: "home",
  components:{
    Header
  },
  data() {
    return {
      activeMenu: '1',  //菜单栏选中索引值
      content:null, // 配置信息
      menuList:[
        {
          path: "/home",
          name: "概览",
          icon: "icon-shouye",
        },
        {
          path: "/storeManagement",
          name: "门店管理",
          icon: "icon-31quanbushangpin",
          children: [
            {
              path: "/storeManagement/storeList",
              name: "门店列表",
              icon: "icon-wodeshangpin",
            },
          ]
        },
        {
          path: "/commodity",
          name: "商品管理",
          icon: "icon-31quanbushangpin",
          children: [
            {
              path: "/commodity/self",
              name: "平台供货库",
              icon: "icon-wodeshangpin",
            },
            {
              path: "/commodity/cattle",
              name: "商户自营库", 
              icon: "icon-shangpinduibi",
            },
            // {
            //   path: "/commodity/auditList",
            //   name: "审核列表", 
            //   icon: "icon-shangpinduibi",
            // }
          ]
        },
        {
          path: "/order",
          name: "订单管理",
          icon: "icon-dingdan",
          children: [
            {
              path: "/order/providedList",
              name: "平台分销订单",
              icon: "icon-dingdanliebiao",
            },
            {
              path: "/order/merchantOrder",
              name: "商户分销订单",
              icon: "icon-wj-shd",
            },
            {
              path: "/order/storeList",
              name: "门店自营订单",
              icon: "icon-dingdanliebiao",
            }
          ]
        },
        {
          path: "/after",
          name: "售后管理",
          icon: "icon-dingdan",
          children: [
            {
              path: "/after/afterProvidedOrder",
              name: "平台分销售后",
              icon: "icon-dingdanliebiao",
            },
            {
              path: "/after/afterMerchantOrder",
              name: "商户分销售后",
              icon: "icon-wj-shd",
            },
            {
              path: "/after/afterStoreOrder",
              name: "门店自营售后",
              icon: "icon-wj-shd",
            }
          ]
        },
        {
          path: "/user",
          name: "用户管理",
          icon: "icon-kehu",
          children: [
            {
              path: "/user/index",
              name: "用户列表",
              icon: "icon-dingdanliebiao",
            },
            {
              path: "/user/levelIndex",
              name: "等级设置",
              icon: "icon-dingdanliebiao",
            }
          ]
        },
        {
          path: "/moneyManagement",
          name: "币类管理",
          icon: "icon-kehu",
          children: [
            {
              path: "/moneyManagement/amountSetting",
              name: "金额设置",
              icon: "icon-dingdanliebiao",
            },
            // {
            //   path: "/moneyManagement/chargeList",
            //   name: "充值记录",
            //   icon: "icon-dingdanliebiao",
            // },
            {
              path: "/moneyManagement/withdrawalList",
              name: "余额提现",
              icon: "icon-dingdanliebiao",
            },
            {
              path: "/moneyManagement/buyMoneyList",
              name: "采购金",
              icon: "icon-dingdanliebiao",
            }
          ]
        },
        // {
        //   path: "/replenish",
        //   name: "补货通知",
        //   icon: "icon-chajian1",
        // },
        {
          path: "/address",
          name: "物流管理",
          icon: "icon-wangpuzhuangxiu",
          children:[
            {
              path: "/address/addressList",
              name: "地址设置",
              icon: "icon-zhuti_o",
            },
            {
              path: "/address/freightTemplate",
              name: "运费模版",
              icon: "icon-zhuti_o",
            }
          ]
        },
        {
          path: "/manage",
          name: "管理员",
          icon: "icon-guanliyuan1",
          children:[
            {
              path: "/manage/adminList",
              name: "管理员列表",
              icon: "icon-guanliyuan",
            },
            {
              path:"/manage/auth",
              name:"角色管理",
              icon: "icon-quanxianguanli",
            },
            {
              path: "/manage/routeList",
              name: "路由列表",
              icon: "icon-luyoubiao",
            }
          ]
        },
        {
          path: "/finance",
          name: "财务管理",
          icon: "icon-shezhi",
          children:[
            {
              path: "/finance/paymentDetail",
              name: "货款明细",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentStatement",
              name: "货款明细对账单",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentManagement",
              name: "货款管理",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentDistributionList",
              name: "分销列表",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentDistributionDetails",
              name: "分销款明细",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentDistributionStatement",
              name: "分销款明细对账单",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/finance/paymentDistributionManagement",
              name: "分销款管理",
              icon: "icon-changyongxinxi",
            }
          ]
        },
        {
          path: "/setup",
          name: "设置",
          icon: "icon-shezhi",
          children:[
            {
              path: "/setup/merchantInfoSetting",
              name: "商户信息设置",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/setup/account",
              name: "提现账号",
              icon: "icon-changyongxinxi",
            },
            {
              path: "/setup/merchantOrderSettings",
              name: "商户订单设置",
              icon: "icon-changyongxinxi",
            },
            // {
            //   path: "/setup/merchantAfterSalesSettings",
            //   name: "商户售后设置",
            //   icon: "icon-changyongxinxi",
            // },
            // {
            //   path: "/setup/auditSettings",
            //   name: "审核设置",
            //   icon: "icon-changyongxinxi",
            // },
            {
              path: "/setup/shopGoodsSet",
              name: "铺货设置",
              icon: "icon-changyongxinxi",
            }
          ]
        },
      ]  //数组
    };
  },
  created() {
    this.activeMenu = localStorage.getItem('activeMenu') ? localStorage.getItem('activeMenu') : '1';
    this.getConfigs();  //获取配置信息
  },
  mounted() {

  },
  computed:{
    isCollapse(){  //展开列表
      return this.$store.state.isCollapse;
    },
    isActiveMenu(){ //获取vuex 右侧返回首页下表数据
      // console.log("数据vuex",this.$store.state.activeMenu)
      return this.$store.state.activeMenu;
    }
  },
  methods: {
    getConfigs(){ //获取配置信息
        getConfig({key:'merchant_page_configuration'}).then(res => {
            this.content = res.content;
        }).catch(err => {});
    },
    toSubMenu(path,item,index){
      // console.log("open11111",path, item,index);
      this.activeMenu = String(index);
      localStorage.setItem('activeMenu',this.activeMenu)
      if(!item.children){
        this.$router.push({ path: path });
      }
    },
    toPage(path,item,idx){
      // console.log("open22222",path, item,idx);
      this.activeMenu = idx;
      localStorage.setItem('activeMenu',this.activeMenu)
      this.$router.push({ path: path });
    },
    handleOpen(key, keyPath) {
      // console.log("open",key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log("close",key, keyPath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-aside {
  width: 206px !important;
  background: #d8e4fb !important;
  height: 100vh;
  .logo{
    height: 80px;
    font-size: 20px;
    color: #639AFF;
    text-align: center;
    // padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #639AFF !important;
    img {
      width: 180px;
      height: 60px;
    }
  }
}
/deep/.el-submenu__title{
  text-align: center;
  span{
    // font-weight: bold;
    font-size: 16px;
  }
}
/deep/.el-menu-item{
  text-align: center;
  height: 53px;
  span{
    // font-weight: bold;
    font-size: 16px;
  }
}
/deep/.el-menu-item.is-active {
  height: 53px;
  text-align: center;
  font-size: 14px;
  background-color: #639AFF !important;
}

/deep/.el-menu-item-group__title{
  padding: 0;
}
/deep/.el-submenu__icon-arrow{
  font-size: 16px;
}
/deep/.el-submenu__title:hover{
  color: #fff !important;
  background: #639AFF !important;
  i{
    color: #fff;
  }
}
/deep/.el-menu-item-group{
  .el-menu-item{
    height: 40px;
    color: #999999 !important;
    line-height: 40px;
    padding: 0 !important;
    text-align: center;
    span{
      width: 3px;
      height: 14px;
      display: inline-block;
      margin-right: 10px;
      background: #d8e4fb !important;
      border-radius: 5px;
      font-weight: bold;
    }
  }
  .el-menu-item:hover{
    color: #fff !important;
    background: #639AFF !important;
  }
  .is-active{
    color: #fff !important;
    background: #639AFF !important;
    span{
      width: 3px;
      height: 14px;
      display: inline-block;
      margin-right: 10px;
      background: #639AFF;
      border-radius: 5px;
    }
  }
  .is-active:hover{
    color: #fff !important;
    background: #639AFF !important;
  }
}

.el-aside_on{
  width: 64px !important;
}
.el-menu{
  border-right: none;
}
.el-main {
  padding: 0;
  color: #333;
  background: #f7f8fa;
  // padding: 10px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  // text-align: center;
  // line-height: 160px;
}
.el-header{
  height: 80px !important;
  color: #333;
  line-height: 80px;
  padding: 0;
}
/deep/.el-tabs__nav-wrap::after{
    background-color:#fff;
}
</style>